function extractFunctionAndArguments(str) {
  const regex = /(\w+)\((.*)\)/;
  const match = str.match(regex);
  if (match) {
    const fn = match[1];
    const argsString = match[2];
    const args = Function(`return [${argsString}]`)();
    return { fn, args };
  }
  return null;
}

function getValueFromPath(obj, path) {
  return new Function('obj', `return obj.${path}`)(obj);
}

const Document = {};

// Document.field('some.path');
Document.field = function (document) {
  	const args = this;
  	return getValueFromPath(document, args[0]);
}

// Document.timestamp();
Document.timestamp = function (document) {
    if (!document._id) return "##&en No _id ##&hu Nincs _id ##";
    const timestamp = document._id.substring(0, 8);
    const date = new Date(parseInt(timestamp, 16) * 1000);
    const options = { weekday: "long", year: "numeric", month: "long", day: "2-digit", hourCycle: "h24" };
    return date.toLocaleTimeString("##&en en ##&hu hu ##", options);
}

// Document.dateField('_createdAt', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit', hourCycle: 'h24' });
Document.dateField = function (document) {
  	const args = this;
  	const value = getValueFromPath(document, args[0]);
    const date = new Date(value);
    let options = args[1] || { weekday: "long", year: "numeric", month: "long", day: "2-digit", hourCycle: "h24" };
    return date.toLocaleTimeString("##&en en ##&hu hu ##", options);
}

// Document._id
Document._id = function (document) {
    return document._id;
}

export default function(document) {
	const o = extractFunctionAndArguments(this);
  	if (!o) return console.log("Invalid function definition: " + this);
  	return Document[o.fn].call(o.args, document);
}
