// /srv/flexpont-project/@gui-modules/controlpanels/vue3/Controlpanel.vue
import Controlpanel from './src/Controlpanel.vue';

// /srv/flexpont-project/@openai-modules/assistant/vue3/Assistant.vue
import Assistant from './src/Assistant.vue';

// /srv/flexpont-project/@openai-modules/assistant/vue3/Assistants.vue
import Assistants from './src/Assistants.vue';

// /srv/flexpont-project/@vue3-modules/app/vue3/App.vue
import App from './src/App.vue';

// /srv/flexpont-project/@vue3-modules/app/vue3/AppButton.vue
import AppButton from './src/AppButton.vue';

// /srv/flexpont-project/@gui-modules/gui/vue3/GuiSelection.vue
import GuiSelection from './src/GuiSelection.vue';

// /srv/flexpont-project/@vue3-modules/checkpoints/vue3/Checkpoints.vue
import Checkpoints from './src/Checkpoints.vue';

// /srv/flexpont-project/@wall-modules/community/vue3/Community.vue
import Community from './src/Community.vue';

// /srv/flexpont-project/@gui-modules/timeline/vue3/Timeline.vue
import Timeline from './src/Timeline.vue';

// /srv/flexpont-project/@gui-modules/timeline/vue3/TimelineEditor.vue
import TimelineEditor from './src/TimelineEditor.vue';

// /srv/flexpont-project/@eventhandler-modules/eventhandlereditor/vue3/EventhandlerEditorDocument.vue
import EventhandlerEditorDocument from './src/EventhandlerEditorDocument.vue';

// /srv/flexpont-project/@eventhandler-modules/eventhandlereditor/vue3/EventhandlerSelection.vue
import EventhandlerSelection from './src/EventhandlerSelection.vue';

// /srv/flexpont-project/@vue3-modules/d3/vue3/HelloD3.vue
import HelloD3 from './src/HelloD3.vue';

// /srv/flexpont-project/@users-modules/login/vue3/Account.vue
import Account from './src/Account.vue';

// /srv/flexpont-project/@users-modules/login/vue3/LoginCard.vue
import LoginCard from './src/LoginCard.vue';

// /srv/flexpont-project/@users-modules/login/vue3/LoginDialog.vue
import LoginDialog from './src/LoginDialog.vue';

// /srv/flexpont-project/@vue3-modules/home/vue3/Home.vue
import Home from './src/Home.vue';

// /srv/flexpont-project/@vue3-modules/home/vue3/HomePage.vue
import HomePage from './src/HomePage.vue';

// /srv/flexpont-project/@vue3-modules/home/vue3/Login.vue
import Login from './src/Login.vue';

// /srv/flexpont-project/@fox-modules/vuefox/vue3/Fox.vue
import Fox from './src/Fox.vue';

// /srv/flexpont-project/@users-modules/profile/vue3/Profile.vue
import Profile from './src/Profile.vue';

// /srv/flexpont-project/@vue3-modules/onlineusers/vue3/OnlineUsers.vue
import OnlineUsers from './src/OnlineUsers.vue';

// /srv/flexpont-project/@database-modules/dataview/vue3/Collection.vue
import Collection from './src/Collection.vue';

// /srv/flexpont-project/@database-modules/dataview/vue3/Database.vue
import Database from './src/Database.vue';

// /srv/flexpont-project/@database-modules/dataview/vue3/Datapoints.vue
import Datapoints from './src/Datapoints.vue';

// /srv/flexpont-project/@database-modules/dataview/vue3/Document.vue
import Document from './src/Document.vue';

// /srv/flexpont-project/@database-modules/queries/vue3/QueriesEditorDocument.vue
import QueriesEditorDocument from './src/QueriesEditorDocument.vue';

// /srv/flexpont-project/@database-modules/queries/vue3/QueriesSelection.vue
import QueriesSelection from './src/QueriesSelection.vue';

// /srv/flexpont-project/@database-modules/queries/vue3/Query.vue
import Query from './src/Query.vue';

// /srv/flexpont-project/@vue3-modules/vuetifind/vue3/HelloTripleSelection.vue
import HelloTripleSelection from './src/HelloTripleSelection.vue';

// /srv/flexpont-project/@vue3-modules/vuetiform/vue3/HelloVuetiformComponent.vue
import HelloVuetiformComponent from './src/HelloVuetiformComponent.vue';

// /srv/flexpont-project/@vue3-modules/vuetiform/vue3/HelloVuetiformPoliform.vue
import HelloVuetiformPoliform from './src/HelloVuetiformPoliform.vue';

export default { Controlpanel, Assistant, Assistants, App, AppButton, GuiSelection, Checkpoints, Community, Timeline, TimelineEditor, EventhandlerEditorDocument, EventhandlerSelection, HelloD3, Account, LoginCard, LoginDialog, Home, HomePage, Login, Fox, Profile, OnlineUsers, Collection, Database, Datapoints, Document, QueriesEditorDocument, QueriesSelection, Query, HelloTripleSelection, HelloVuetiformComponent, HelloVuetiformPoliform };