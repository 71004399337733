export default function converter(str) {
    // Trim whitespace
    str = str.toString().trim();

    // Remove accents, swap ñ for n, etc
    const on = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖŐÒÔÕØŘŔŠŤÚŮÜŰÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöőòôõøðřŕšťúůüűùûýÿžþÞĐđßÆa";
    const to = "AAAAAACCCDEEEEEEEEIIIINNOOOOOOORRSTUUUUUUYYZaaaaaacccdeeeeeeeeiiiinnoooooooorrstuuuuuuyyzbBDdBAa";
    for (var i = 0, l = on.length; i < l; i++) str = str.replace(new RegExp(on.charAt(i), "g"), to.charAt(i));

    // Remove invalid chars and replace spaces with underscores
    str = str.replace(/[^a-z]/gi, "_").toLowerCase();

    // Remove leading underscores
    str = str.replace(/^_+/, "");

    // Replace multiple underscores with a single underscore
    str = str.replace(/_+/g, "_");

    return str;
}

converter.title = "##&en Variable ##&hu változó ##";
