<template>
    <vuetiform-component :format :modelValue @update:modelValue="updateHandler" :bond :ref="getRef('component')" />
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch, nextTick } from "vue";
import { isProxy, toRaw } from "vue";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();

import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";
import { structuredClone as clone } from "../../helper-functions.mjs";

const props = defineProps(["format", "modelValue", "bond"]);
const emit = defineEmits(["update:modelValue"]);

const data = reactive({
    modelValue: props.modelValue,
});

// :ref="getRef('component')"
const refs = reactive({
    component: null,
});

function getRef(key) {
    return (el) => (refs[key] = el);
}

async function refresh() {
    if (refs.component) if (refs.component.refresh) refs.component.refresh();
}

function updateHandler(value, nexus = {}) {
    data.valid = nexus.valid || true;
    data.modelValue = value;
    if (nexus.change) data.change = true;
}
function updateEmitter() {
    emit("update:modelValue", data.modelValue, { valid: data.valid, change: data.change });
}

defineExpose({ refresh });
</script>
<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-functional",
};
</script>
