<template>
    <v-file-input
        :label="props.label || 'File'"
        v-model="data.files"
        @update:modelValue="updateHandler"
        :accept="props.accept"
        :disabled="props.disabled"
        @click:clear="clearHandler"
    ></v-file-input>
    <file-display v-if="props.display && data.files.length" :module :channel :bond="bond" :subpath="subpath" :filename="data.filename" :key="data.filename" />
</template>
<script setup>
import { ref, reactive, watch, nextTick, toRaw, computed } from "vue";
//import { structuredClone } from "../../helper-functions.mjs";
import FileDisplay from "@/vuetiform-components/FileDisplay.vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const emit = defineEmits(["update:modelValue", "update:valid", "update", "input", "accept"]);
const props = defineProps(["bond", "module", "channel", "modelValue", "subpath", "label", "is", "class", "disabled", "display"]);

let files = [];
if (props.modelValue) files = [new File([], props.modelValue)];
const data = reactive({ file: props.modelValue, files, filename: props.modelValue });

function isValid() {
    if (props.mandatory === true) if (data.files.length < 1) return (props.label || "") + " ##&en file is required ##&hu file megadása kötelező kötelező ##";
    return true;
}

function clearHandler() {
    data.files = [];
    const valid = isValid();
    emit("update:modelValue", undefined, { valid, change: true });
}

const updateHandler = async () => {
    await nextTick();

    const valid = isValid();
    let datum = null;
    // TODO ez mintha nem menne
    //emit("update:valid", false);
    if (valid !== true) return;
    if (!data.files) return;

    const file = data.files[0];
    if (!file) return;

    const eventName = (props.module || "vuetifiles") + ":" + (props.channel || "vuetifiles") + ":upload";

    Ł("VuetiformFile upload", eventName);

    $socket.emit(
        eventName,
        file,
        {
            name: file.name,
            type: file.type,
            subpath: props.subpath,
            route: route.path,
            bond: props.bond,
        },
        ({ filename }) => {
            if (!filename) return emit("update:valid", false);
            data.files = [new File([], filename)];
            datum = filename;
            data.filename = filename;
            emit("update:modelValue", datum, { valid, change: true });
            //console.log("VuetiformFile callback");
        },
    );
};
const updateMenu = (e) => {
    if (!selecting) data.index = null;
};
</script>
<script>
export default {
    inheritAttrs: false,
};
</script>
