<template>
    <v-card-title class="pa-2">
        <h2>{{ document.name || document._id || "##&en New document ##&hu Új dokumentum ##" }}</h2>
        <v-btn flat icon="mdi-triangle-small-down" size="small" @click.native="info.expanded = !info.expanded" style="background-color: transparent" />
    </v-card-title>

    <v-card-subtitle v-if="info.expanded">
        <h3 class="pa-2">##&en Document ##&hu Dokumentum ##</h3>

        <pre>{{ data.host || hostname }} {{ data.pattern }} {{ database }}.{{ collection }} {{ document._id || props.id }} {{ displayVersion(document) }}</pre>
        <pre>{{ displayDate(document._createdAt) }} {{ data.createdBy }}</pre>
        <pre>{{ displayDate(document._updatedAt) }} {{ data.updatedBy }}</pre>

        <h3 class="pa-2">##&en Referencers ##&hu Referenciájában ##</h3>
        <div v-for="(v, i) in document.__referencers || {}" class="sameline">
            <v-icon small @click="openDocument(v)" class="clickable-icon"> mdi-open-in-app </v-icon>
            <pre> {{ v.name }}</pre>
        </div>
        <h3 class="pa-2">##&en Dependents ##&hu Függőségeiben ##</h3>
        <div v-for="(v, i) in document.__dependents || {}" class="sameline">
            <v-icon small @click="openDocument(v)" class="clickable-icon"> mdi-open-in-app </v-icon>
            <pre> {{ v.name }}</pre>
        </div>
    </v-card-subtitle>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const props = defineProps(["database", "collection", "id", "data", "document", "format"]);

const database = props.database;
const collection = props.collection;
const id = props.id;
const data = props.data;
const info = reactive({
    expanded: false,
});
const hostname = (ß.HOSTNAME || "localhost").split(".")[0];

const options = props.options || {
    year: "numeric",
    month: "numeric",
    day: "2-digit",
    //weekday: "long",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
};

function displayDate(date) {
    if (!date) return "";
    date = new Date(date);

    const formatter = new Intl.DateTimeFormat("##&en en-US ##&hu hu-HU ##", options);
    return formatter.format(date);
}

function displayVersion(_document) {
    if (!_document) return "";
    if (!_document._version) return "";
    return "#" + parseInt(_document._version.slice(-6), 16) + " " + _document._version;
}

const openCollection = () => {
    router.push("/database/" + database + "/" + collection);
};
const openDocument = ({ database, collection, id }) => {
    router.push("/document/" + database + "/" + collection + "/" + id);
};
const openRemote = () => {
    if (!data.host) return;
    window.location.href = "https://" + data.host + "/document/" + database + "/" + collection + "/" + props.document._id;
};
</script>

<style scoped>
.pointer {
    cursor: pointer;
}
.sameline {
    display: flex;
    align-items: center;
}
</style>
