import converters from "../../converters.mjs";
export default function (format) {
    if (!format.converters) return;
    const transformations = [];

    let list = format.converters || [];
    if (typeof format.converters === "string") list = format.converters.split("|");
    for (const i of list)
        transformations.push((input) => {
            return converters[i](input);
        });

    return (datum) => {
        for (const tf of transformations) datum = tf(datum);
        return datum;
    };
}
