<template>
    <!--p class="ma-2 pl-2 text-h6" v-if="props.label">{{ props.label }}</p-->
    <vuetiform-component
        v-if="data.format"
        :node="props.node"
        :bond="props.bond"
        :format="data.format"
        :modelValue="data.modelValue"
        @update:modelValue="updateHandler"
        :ref="getRef('component')"
        :key="data.key"
        :label
    />
</template>

<script setup>
// dynamically load data from socket.io

import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";
import { structuredClone } from "../../helper-functions.mjs";
import { compare } from "../../helper-functions.mjs";
import { ref, reactive, watch, nextTick, onMounted, onUnmounted, toRaw } from "vue";

const Đ = (msg, ...args) => {
    //Ł("$$$", msg, JSON.stringify(...args));
};

function clone(p) {
    return structuredClone(toRaw(p));
}

const props = defineProps([
    "node",
    "bond",
    "format",
    "modelValue",
    "identifier",
    "module",
    "channel",
    "label",
    "updateOnComponentValue", // bool
    "updateOnComponentChange", // bool
    "updateOnSocketEvent", // socket.io event name
    "updateOnComponentRefresh", // bool
    "serverform", // object
]);

const emit = defineEmits(["update:modelValue"]);

let { updateOnComponentValue, updateOnComponentChange, updateOnSocketEvent, updateOnComponentRefresh, serverform } = props;

const data = reactive({
    format: props.format,
    modelValue: props.modelValue,
    valid: true,
    key: 0,
});

const refs = reactive({
    component: null,
});

function getRef(key) {
    return (el) => (refs[key] = el);
}

function refresh() {
    Đ("refresh");
    if (updateOnComponentRefresh) serverUpdate("refresh");
}
defineExpose({ refresh });

async function refreshComponent() {
    await nextTick();
    if (!refs.component) return;
    if (!refs.component.refresh) return;
    Đ("refreshComponent");
    refs.component.refresh();
}

function updateValue(datum, nexus = { valid: true, change: true }) {
    data.modelValue = datum;
    data.valid = nexus.valid;
    Đ("updateValue", { datum });
    emit("update:modelValue", datum, { valid: data.valid, change: nexus.change });
    return datum;
}

function updateHandler(datum, nexus = { valid: true, change: false }) {
    updateValue(datum, nexus);
    if (updateOnComponentValue) return serverUpdate("updateHandler", nexus);
    if (nexus.change) if (updateOnComponentChange) return serverUpdate("updateHandler", nexus);
}

function updateEmitter(datum = data.modelValue, nexus = { valid: true, change: false }) {
    emit("update:modelValue", datum, { valid: data.valid, change: nexus.change });
}

/*
function formatUpdate(format) {
    Object.assign(data, { format });
}
//*/

///*
//import formatFunctions from "@/vuetiform/formatFunctions.mjs";
function formatUpdate(format) {
    data.format = format;
    //data.format = formatFunctions.call(
    //{ __from_VuetiformServerform_formatUpdate: true, Document: () => toRaw(props.bond?.document || {}), Format: () => toRaw(data.format) },
    //    { __from_VuetiformServerform_formatUpdate: true, ...props.bond?.Context() },
    //    _format,
    //);
}
//*/

async function serverUpdate(event, nexus = { valid: true, change: false }) {
    const module = props.module;
    const channel = props.channel;
    $socket.emit("vuetiform:serverform:update", { module, channel, props, data, event }, (o) => {
        if ("format" in o) formatUpdate(o.format);
        if ("modelValue" in o) updateValue(o.modelValue, o.nexus || nexus);
        if (Object.keys(o).length > 0) refreshComponent();
        Đ("serverUpdated", o);
    });
}
  
onMounted(async () => {
    if (updateOnSocketEvent)
        $socket.on(updateOnSocket, (o) => {
            serverUpdate(updateOnSocketEvent);
        });
    serverUpdate("onMounted");
});

onUnmounted(async () => {
    if (updateOnSocketEvent) $socket.removeListener(updateOnSocketEvent);
});
</script>

<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-serverform",
};
</script>
