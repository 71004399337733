<template>
    <div
        v-if="data.condition"
        :class="{
            editing: data.editing,
            valid: data.valid === true,
            invalid: data.valid !== true,
            readonly: data.format.readonly === true,
            rednull: data.format.rednull && data.modelValue === null,
            preinvalid: data.format.valid !== undefined && data.format.valid !== true,
        }"
    >
        <component
            :is="data.format.is"
            v-bind="data.format.vuetifyprops || { ...data.format, bond: props.bond, node: props.node }"
            :modelValue="data.modelValue"
            @update:modelValue="updateHandler"
            @input="inputHandler"
            @click:clear="clearHandler"
            @change="changeHandler"
            class="pt-3 pl-3 pr-3 pb-1"
            :ref="getRef('component')"
            :rules="data.rules"
        />
        <!--pre class="pl-6">{{ getTextShadow() }}</pre-->
    </div>
</template>

<script setup>
//  :bond="props.bond"

// this components goal is to merge vuetify and vuetiform components, and add a layer of common extra functionality
// keep it minimalistic

import { ref, reactive, watch, nextTick, toRaw, onMounted } from "vue";
import { compare } from "../../helper-functions.mjs";
import getFormatValidationRules from "@/vuetiform/getFormatValidationRules.mjs";
import getFormatConverterTransformator from "@/vuetiform/getFormatConverterTransformator.mjs";

function isObject(i) {
    return Object.prototype.toString.call(i) === "[object Object]";
}

const props = defineProps(["bond", "node", "format", "modelValue", "disabled", "readonly"]);
const emit = defineEmits(["update:modelValue", "update:valid", "change"]);
const data = reactive({ format: { bond: props.bond }, modelValue: props.modelValue, condition: true, valid: true, editing: false });

let łog = (msg) => {
    console.log("..", (props.format.vuetifyprops || {}).label || props.format.label || "no-label@" + props.format.is, "::", msg);
};

//if (props.format.label === "Text")
//łog = console.log;

function getTextShadow() {
    if (!props.bond) return;
    if (!props.node) return;
    if (!props.bond.Context) return;
    const context = props.bond.Context();
    const document = context.document();
    if (!document) return;
    return document.__textshadow[props.node];
}

function getFormatShadow() {
    if (!props.bond) return;
    if (!props.node) return;
    if (!props.bond.Context) return;
    const context = props.bond.Context();
    const document = context.document();
    if (!document) return;
    return (document.__formatshadow || {})[props.node];
}

//let default_value_is_set = false;
async function setDefaultValue() {
    if (data.modelValue !== undefined) return false;
    //if (default_value_is_set) return false;

    /*if (data.format.getDefaultValue) {
        //łog("setDefaultValue...", data.modelValue);
        //console.trace();
        if (typeof data.format.getDefaultValue !== "function") return console.error("%cError in format.getDefaultValue, not a function ", "color: red;");
        data.modelValue = await data.format.getDefaultValue.call(Context(), null);
        //łog("setDefaultValue;;;", data.modelValue);
        if (data.modelValue !== undefined) return true;
        else console.error("%cError in format.getDefaultValue, returned value is undefined ", "color: red;");
    }*/

    if (data.format.default !== undefined) {
        //łog("setDefaultValue---", data.modelValue);

        data.modelValue = data.format.default;
        //default_value_is_set = true;
        if (data.modelValue !== undefined) return true;
    }

    return false;
}

function Context() {
    let o = {};
    if (props.bond?.Context) o = props.bond.Context();
    return { ...o, datafield: () => toRaw(props.format || {}), __from_VuetiformComponent_context: true };
}

//import functionalFormat from "@/vuetiform/functionalFormat.mjs";
async function updateFunctionalFormat() {
    if (!props.format) {
        console.error("%cError: Vuetiform component must have a format prop!!", "color: red;");
        console.log(toRaw(props));
    }

    data.format = props.format;
    const formatshadow = getFormatShadow() || {};
    for (const key in formatshadow) data.format[key] = formatshadow[key];

    let document = {};
    if (typeof props.bond?.Context === "function") document = props.bond.Context().document();

    //data.format = props.format;
    //data.format = await functionalFormat.call(Context(), { format: toRaw(props.format || {}), value: props.modelValue });
    data.format.rules = getFormatValidationRules(props.format);
    const transformator = getFormatConverterTransformator(props.format);
    if (transformator) data.format.transformator = getFormatConverterTransformator(props.format);

    //łog("updateFunctionalFormat2");

    if (props.format.condition)
        if (props.format.condition.hashtags) {
            //łog("updateFunctionalFormat3");
            const tags = props.format.condition.hashtags;

            if ((document._hashtags || []).some((tag) => tags.includes(tag))) {
                data.condition = true;
                //Ł("updateFunctionalFormat, change due to condition true");
            } else {
                data.condition = false;
                if (data.modelValue !== undefined) {
                    //Ł("updateFunctionalFormat, change due to condition false");
                    data.modelValue = undefined;
                    updateEmitter(undefined, { change: true });
                    return;
                }
            }
        }
    //*/
}

async function updateFunctionalValues() {
    if (!data.condition) return;

    //łog("updateFunctionalValues");

    if (data.format.getComponentValue) {
        if (typeof data.format.getComponentValue !== "function") return console.error("%cError in format.getComponentValue, not a function ", "color: red;");
        const modelValue = await data.format.getComponentValue.call(Context(), data.modelValue);

        //updateHandler(modelValue);

        data.modelValue = modelValue;
        //Ł("updateFunctionalValues:getComponentValue", data.format.getComponentValue, data.modelValue);
        return;
    }

    if (await setDefaultValue()) {
        //łog("setDefaultValue true");
        return updateEmitter();
    }
}

// :ref="getRef('component')"
const refs = reactive({
    component: null,
});

function getRef(key) {
    return (el) => (refs[key] = el);
}

async function refresh() {
    //łog("refresh"); //, { "data.modelValue": data.modelValue, "props.modelValue": props.modelValue });

    await updateFunctionalFormat();
    if (!data.condition) return;

    //if (compare(toRaw(data.modelValue), toRaw(props.modelValue)) && data.valid === isValid()) return łog("skip-refresh", );
    //łog("refresh1", { "data.modelValue": data.modelValue, "props.modelValue": props.modelValue });
    data.modelValue = props.modelValue;
    //data.editing = false;
    await updateFunctionalValues();
    //łog("refresh2", { "data.modelValue": data.modelValue, "props.modelValue": props.modelValue });
    await refreshComponent();
    //łog("refresh3", { "data.modelValue": data.modelValue, "props.modelValue": props.modelValue });
    const valid = isValid();
    //łog("refresh valid:", valid);
    if (valid !== data.valid) {
        await nextTick();
        //łog("refresh validity", valid);
        return updateEmitter(data.modelValue, { valid, change: false });
    }
    return "OK refresh @ VuetiformComponent " + data.format.is;
}

async function refreshComponent() {
    await nextTick();
    if (!refs.component) return;
    if (!refs.component.refresh) return;
    //łog("refreshComponent");
    refs.component.refresh();
}

function isValid(modelValue = data.modelValue) {
    const format = data.format;
    if (!format) return true;
    if (data.valid !== true) return data.valid;
    if (format.condition === false) return true;
    if (format.readonly === true) return true;
    if (format.mandatory === true) {
        if (data.condition === false) return true;
        if (modelValue === undefined || modelValue === null) return (format.label || "") + " ##&en Mandatory field! ##&hu Kötelező mező! ##";
        if (modelValue === "") return (format.label || "") + " ##&en Can not be blank ##&hu Nem lehet üres ##";

        if (format.items) {
            const items = format.items.map((e) => (isObject(e) ? e.value : e));
            if (items.includes(modelValue)) return true;
            return (format.label || "") + " ##&en Invalid selection ##&hu Érvénytelen választott mező ##";
        }
    }
    const rules = getFormatValidationRules(format);
    for (const rule of rules) {
        // check if i is a valid validator? check if modelValue is a string?
        const r = rule(modelValue);
        if (r === false) "???";
        if (r === false) return "##&en Invalid. ##&hu Érvénytelen bejegyzés ##";
        if (r !== true) return (format.label || "") + " - " + r;
    }

    return true;
}

defineExpose({ refresh, isValid });

function typeConverter(datum) {
    if (!data.format.type) return { datum, invalid: false };
    if (typeof datum === data.format.type.toLowerCase()) return { datum, invalid: false };
    if (data.format.type === "String") return datum.toString();
    if (data.format.type === "Number") {
        //let d = datum.replace(/,/g, ".").replace(/\s/g, "");
        //if (d.endsWith(".")) d += "0";
        //

        const n = Number(datum);
        //łog("typeConverter", { n, datum, type: typeof datum });

        if (isNaN(n)) return { datum, invalid: "##&en Invalid number representation ##&hu Érvénytelen számábrázolás ## (NaN)" };
        if (datum.toString() !== n.toString()) return { datum, invalid: "##&en Invalid number representation ##&hu Érvénytelen számábrázolás ##" };
        return { datum: n, invalid: false };
    }
    return { datum, invalid: false };
}

function inputHandler(event) {
    //łog("inputhandler");
}

async function updateHandler(modelValue, nexus = {}) {
    //łog("updateHandler");
    if (data.format.vuetifyprops) data.editing = true;
    if (nexus.valid === undefined) nexus.valid = true;
    if (nexus.change === undefined) nexus.change = false;

    if (data.format.transformator) {
        data.modelValue = null;
        modelValue = data.format.transformator(modelValue);
    }

    const { datum, invalid } = typeConverter(toRaw(modelValue));
    if (invalid)
        if (nexus.valid === true) nexus.valid = invalid;
        else nexus.valid += invalid;

    //console.log(modelValue, typeof datum, datum, invalid);

    data.valid = nexus.valid;
    data.modelValue = datum;

    //łog("updateHandler", { datum, nexus });
    updateEmitter(datum, nexus);
}

function updateEmitter(datum = data.modelValue, nexus = {}) {
    if (nexus.valid === undefined) nexus.valid = true;
    if (nexus.change === undefined) nexus.change = false;

    let valid = isValid(datum);

    nexus.valid = valid;
    data.valid = valid;

    if (["v-select", "v-autocomplete", "v-combobox", "database-document"].includes(data.format.is)) nexus.change = true;

    //łog("updateEmitter", nexus.change);
    if (nexus.change) data.editing = false;
    emit("update:modelValue", datum, nexus);
}

function changeHandler(datum) {
    //łog("changeHandler");
    //łog("changeHandler", { datum, "data.modelValue": data.modelValue });
    updateEmitter(data.modelValue, { change: true });
}

async function clearHandler() {
    //default_value_is_set = false;
    data.modelValue = undefined;
    await setDefaultValue();
    //łog("clearHandler", { "data.modelValue": data.modelValue });
    updateHandler(data.modelValue, { change: true });
}

onMounted(async () => {
    //łog("onMounted started");
    await updateFunctionalFormat();
    await updateFunctionalValues();
    data.valid = isValid();
    if (data.valid !== true) updateEmitter();
    //łog("onMounted complete");
});
</script>

<script>
import * as vuetify from "vuetify/components";
import vuetiform from "../../vuetiform-components.mjs";

export default {
    inheritAttrs: false,
    components: { ...vuetify, ...vuetiform },
    name: "vuetiform-component",
};
</script>
<style scoped>
.editing {
    background-color: rgba(255, 235, 59, 0.1);
    border-left: 3px solid yellow !important;
}
.valid {
    border-left: 2px solid rgba(0, 0, 0, 0);
}
.invalid {
    background-color: rgba(128, 0, 0, 0.02);
    border-left: 3px solid red;
}
.readonly {
    background-color: rgba(125, 125, 125, 0.2);
    border-left: 2px solid gray;
}
.rednull {
    background-color: rgba(255, 125, 125, 0.2);
    border-left: 1px solid red;
}
.preinvalid {
    background-color: rgba(128, 0, 0, 0.1);
    border-left: 2px solid orange;
}
</style>
