export default function convertNumber(datum) {
    //return datum.toString().replace(/,/g, ".").replace(/\s/g, "").replace(/[^0-9.]/gi, "");

    // Convert to string and remove all spaces
    let cleanedString = datum.toString().replace(/\s/g, "");
    
    // Replace commas with dots
    cleanedString = cleanedString.replace(/,/g, ".");
    
    // Remove any character that is not a digit, a dot, or a minus sign (at the beginning)
    cleanedString = cleanedString.replace(/[^0-9.-]/g, "");
    
    // Ensure there's only one dot, and it isn't at the start
    let parts = cleanedString.split('.');
    if (parts.length > 2) {
        cleanedString = parts[0] + '.' + parts.slice(1).join('');
    }

    // Remove additional minus signs
    if (cleanedString.indexOf('-') > 0) {
        cleanedString = cleanedString.replace(/-/g, '');
    }

    return cleanedString;
}

convertNumber.title = "##&en Number ##&hu Szám ##";
