<template>
    <v-container fluid class="pa-16">
        <v-toolbar>
            <v-btn icon size="large" @click.native="emitDocument('save')" :disabled="data.valid !== true">
                <v-icon>mdi-floppy</v-icon>
            </v-btn>
            <v-btn icon size="large" @click.native="emitDocument('find')" :disabled="data.valid !== true">
                <v-icon>mdi-database-search</v-icon>
            </v-btn>
            <v-toolbar-title>
                <pre>
 {{ route.params.database }} ##&en Query ##&hu Lekérdezés ## {{ data.query.database }} / {{ data.query.collection || "-" }} / {{ data.query.name || route.params.id }} </pre
                >
            </v-toolbar-title>
            <v-spacer />
            <v-btn icon size="large" @click.native="emitDocument('trash')">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </v-toolbar>
        <pre class="pb-2" :class="{ 'text-red': data.valid !== true }">{{ data.valid !== true ? data.valid : "-" }}</pre>
        <vuetiform-component :bond="getBond()" :format="{ is: 'vuetiform-poliform', format: data.format }" :modelValue="data.query" @update:modelValue="updateHandler" :key="data.key" />
    </v-container>
    <div>
        <v-btn flat icon="mdi-triangle-small-down" size="small" @click.native="data.debug = !data.debug" style="background-color: transparent" />
        <pre v-if="data.debug">{{ data }}</pre>
    </div>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch, toRaw } from "vue";
import { useRouter, useRoute } from "vue-router";

import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";
//import Vuetiform from "@/vuetiform-components/QueriesForm.vue";

const router = useRouter();
const route = useRoute();

import { useQueryStore } from "@/store/queryStore.mjs";
const store = useQueryStore();

function getBond() {
  	// a bond a végén volt
    return { id: route.params.id, Context, stack: [] };
}
  
const bond = {
    //database: route.params.database,
    //collection: route.params.collection,
    id: route.params.id,
};

const data = reactive({
    query: {},
    initialValues: {},
    key: 0,
    format: {},
  	valid: true
});

function Context() {
    return {
      	//host: () => data.host,
      	database: () => data.database,
      	collection: () => data.collection,
        document: () => toRaw(data.query),
        format: () => toRaw(data.format),
      	version: () => data.version,
    };
}

  
const emitDocument = (act) => {
    $socket.emit(
        "queries:QueriesEditorDocument:" + act,
        {
            database: route.params.database,
            collection: route.params.collection,
            id: route.params.id,
            query: data.query,
        },
        (o) => {
            if (!o) return;
            if (o.uri) return router.push(o.uri);
            Object.assign(data, o);
            store.assign(o);
            data.key++;
        },
    );
};

const update = () => {
    $socket.emit(
        "queries:QueriesEditorDocument:update",
        {
            database: route.params.database,
            collection: route.params.collection,
            id: route.params.id,
        },
        (o) => {
            if (!o) return;
            Ł(o);
            Object.assign(data, o);
            store.assign(o);
            data.key++;
        },
    );
};

async function updateHandler(query, nexus = { valid: true, change: false }) {
    Ł(query, nexus);
    data.valid = nexus.valid;
    data.query = query;
}

onMounted(async () => {
    await $connection();
    update();
});
</script>
<script>
export default {
    path: "/queries/:database/:collection/:id",
    name: "queries-editor-document",
    icon: "mdi-cog-box",
    sort: 110,
    root: "Home",
};
</script>
