// /srv/flexpont-project/@eventhandler-modules/actions/vue3/vuetiform-components/EventhandlerJavascript.vue
import EventhandlerJavascript from './src/vuetiform-components/EventhandlerJavascript.vue';

// /srv/flexpont-project/@wall-modules/comments/vue3/vuetiform-components/CommentChip.vue
import CommentChip from './src/vuetiform-components/CommentChip.vue';

// /srv/flexpont-project/@wall-modules/comments/vue3/vuetiform-components/CommentSection.vue
import CommentSection from './src/vuetiform-components/CommentSection.vue';

// /srv/flexpont-project/@wall-modules/comments/vue3/vuetiform-components/LikesChip.vue
import LikesChip from './src/vuetiform-components/LikesChip.vue';

// /srv/flexpont-project/@eventhandler-modules/eventhandlereditor/vue3/vuetiform-components/EventhandlerContextkeySelect.vue
import EventhandlerContextkeySelect from './src/vuetiform-components/EventhandlerContextkeySelect.vue';

// /srv/flexpont-project/@eventhandler-modules/eventhandlereditor/vue3/vuetiform-components/EventhandlerContextkeySolvableText.vue
import EventhandlerContextkeySolvableText from './src/vuetiform-components/EventhandlerContextkeySolvableText.vue';

// /srv/flexpont-project/@eventhandler-modules/eventhandlereditor/vue3/vuetiform-components/EventhandlerContextkeySolvableTextCombobox.vue
import EventhandlerContextkeySolvableTextCombobox from './src/vuetiform-components/EventhandlerContextkeySolvableTextCombobox.vue';

// /srv/flexpont-project/@database-modules/datafields/vue3/vuetiform-components/DatabaseDocument.vue
import DatabaseDocument from './src/vuetiform-components/DatabaseDocument.vue';

// /srv/flexpont-project/@database-modules/datafields/vue3/vuetiform-components/DatabaseDocumentList.vue
import DatabaseDocumentList from './src/vuetiform-components/DatabaseDocumentList.vue';

// /srv/flexpont-project/@eventhandler-modules/interpreters/vue3/vuetiform-components/InterpreterEvaluation.vue
import InterpreterEvaluation from './src/vuetiform-components/InterpreterEvaluation.vue';

// /srv/flexpont-project/@database-modules/queries/vue3/vuetiform-components/QueriesForm.vue
import QueriesForm from './src/vuetiform-components/QueriesForm.vue';

// /srv/flexpont-project/@database-modules/queries/vue3/vuetiform-components/VuetiformQueriesForm.vue
import VuetiformQueriesForm from './src/vuetiform-components/VuetiformQueriesForm.vue';

// /srv/flexpont-project/@vue3-modules/vuetifiles/vue3/vuetiform-components/FileDisplay.vue
import FileDisplay from './src/vuetiform-components/FileDisplay.vue';

// /srv/flexpont-project/@vue3-modules/vuetifiles/vue3/vuetiform-components/VuetiformFile.vue
import VuetiformFile from './src/vuetiform-components/VuetiformFile.vue';

// /srv/flexpont-project/@vue3-modules/vuetiform/vue3/vuetiform-components/HashtagsCombobox.vue
import HashtagsCombobox from './src/vuetiform-components/HashtagsCombobox.vue';

// /srv/flexpont-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformArray.vue
import VuetiformArray from './src/vuetiform-components/VuetiformArray.vue';

// /srv/flexpont-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformCodemirror.vue
import VuetiformCodemirror from './src/vuetiform-components/VuetiformCodemirror.vue';

// /srv/flexpont-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformDate.vue
import VuetiformDate from './src/vuetiform-components/VuetiformDate.vue';

// /srv/flexpont-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformDialog.vue
import VuetiformDialog from './src/vuetiform-components/VuetiformDialog.vue';

// /srv/flexpont-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformDuration.vue
import VuetiformDuration from './src/vuetiform-components/VuetiformDuration.vue';

// /srv/flexpont-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformFunctional.vue
import VuetiformFunctional from './src/vuetiform-components/VuetiformFunctional.vue';

// /srv/flexpont-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformFunctionalValue.vue
import VuetiformFunctionalValue from './src/vuetiform-components/VuetiformFunctionalValue.vue';

// /srv/flexpont-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformList.vue
import VuetiformList from './src/vuetiform-components/VuetiformList.vue';

// /srv/flexpont-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformObject.vue
import VuetiformObject from './src/vuetiform-components/VuetiformObject.vue';

// /srv/flexpont-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformPoliform.vue
import VuetiformPoliform from './src/vuetiform-components/VuetiformPoliform.vue';

// /srv/flexpont-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformPretext.vue
import VuetiformPretext from './src/vuetiform-components/VuetiformPretext.vue';

// /srv/flexpont-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformServerform.vue
import VuetiformServerform from './src/vuetiform-components/VuetiformServerform.vue';

export default { EventhandlerJavascript, CommentChip, CommentSection, LikesChip, EventhandlerContextkeySelect, EventhandlerContextkeySolvableText, EventhandlerContextkeySolvableTextCombobox, DatabaseDocument, DatabaseDocumentList, InterpreterEvaluation, QueriesForm, VuetiformQueriesForm, FileDisplay, VuetiformFile, HashtagsCombobox, VuetiformArray, VuetiformCodemirror, VuetiformDate, VuetiformDialog, VuetiformDuration, VuetiformFunctional, VuetiformFunctionalValue, VuetiformList, VuetiformObject, VuetiformPoliform, VuetiformPretext, VuetiformServerform };