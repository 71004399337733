<template>
    <div :class="props.classes || 'text-h7 pa-1 ml-2'">
        <div class="pa-1">{{ props.label }}</div>
        <pre class="pa-1"> {{ data.displayValue }}</pre>
    </div>
</template>
<script setup>
import { ref, reactive, watch, nextTick, onMounted, toRaw } from "vue";

const props = defineProps(["label", "modelValue", "classes", "getTextValue"]);

const data = reactive({ displayValue: props.modelValue || '' });

async function refresh() {
    data.displayValue = props.modelValue || '';
    if (props.getTextValue) data.displayValue = await props.getTextValue(props.modelValue);
}

defineExpose({ refresh });
onMounted(async () => {
    refresh();
});
</script>

<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-functional-value",
};
</script>
